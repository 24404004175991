import React, { useEffect, useMemo, useState } from "react";
import "./Function.scss";
import Select from "react-select";
import { customStyles } from "./styleSelector";
import {
  optionsDataBit,
  optionsDataByte,
  optionsDataOrder,
  optionsDataSigned,
  optionsDataType,
} from "./data";
import { FormattedMessage, useIntl } from "react-intl";
import { FunctionEditorPropsType } from "./FunctionTypes";
import { ReactComponent as BtnSve } from "./svg/Save.svg";
import { ReactComponent as EditGroupIcon } from "./svg/EditGroupIcon.svg";
import ButtonBack from "../buttons/ButtonBack";
import { DataType, FunctionDTO } from "../../../api/auth/apiClient";
import { mapClient } from "../../../api/auth/AxiosInstanse";
import ButtonEdit from "../buttons/ButtonEdit";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import Modal from "../Modal/Modal";
import { NotificationType } from "../../Notification/notificationTypes";
import StatusSettings from "./StatusSettings/StatusSettings";
import FunctionsGroups from "./Functions Groups/FunctionsGroups";
import { UserRole } from "../../Menu/Menu";
import { rolesForForms } from "../TransportEdit";
import CanIdentifier from "./CanIdentifier/CanIdentifier";

function Function(props: FunctionEditorPropsType) {
  const [buttonActive, setButtonActive] = useState(false);
  const [showModalFunction, setModalFunction] = useState(false);
  const [functionBeforeEdit, setFunctionBeforeEdit] = useState<FunctionDTO>();
  const [showModalGroups, setShowModalGroups] = useState(false);
  const [showModalCan, setShowModalCan] = useState(false);
  const intl = useIntl();
  const [dataType, setDataType] = useState<DataType>(
    useMemo(() => {
      return props.selectedRow.dataType;
    }, [props])
  );
  const methods = useForm<FunctionDTO>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return props.selectedRow;
    }, [props]),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
  } = methods;
  watch("dataBit");
  watch("dataSigned");
  watch("dataOrder");
  watch("groupId");
  watch("canId");

  useEffect(() => {
    reset(props.selectedRow);
  }, [props.selectedRow]);
  function changeData(data: FunctionDTO) {
    if (data.dataType !== DataType.Bit) {
      data = {
        ...data,
        dataBit: undefined,
      } as FunctionDTO;
    }
    if (data.dataType !== DataType.TwoByte) {
      data = {
        ...data,
        dataSigned: undefined,
        dataOrder: undefined,
      } as FunctionDTO;
    }
    return data;
  }
  function onChangeDisabledFunction(isDisabledFunction: boolean) {
    props.setIsDisabledFunction(isDisabledFunction);
    setFunctionBeforeEdit({ ...props.selectedRow } as FunctionDTO);
  }
  function onClose() {
    props.setSelectedRow(functionBeforeEdit!);
    props.onShowEditorTable(false);
  }
  function onClickButtonBack(showModal: boolean) {
    if (!props.isDisabledFunction) {
      setModalFunction(showModal);
    } else {
      onClose();
    }
    setButtonActive(!buttonActive);
  }
  function changeStatusCriteria(data: FunctionDTO) {
    if (data.statusCriteria?.length === 0) {
      return {
        ...data,
        statusCriteria: undefined,
      } as FunctionDTO;
    }
    return {
      ...data,
      statusCriteria: data.statusCriteria?.map((s) => {
        if (+s.id! < 0) {
          return {
            ...s,
            id: undefined,
          };
        }
        return s;
      }),
    } as FunctionDTO;
  }
  async function createFunction(data: FunctionDTO) {
    let resultCreateFunction;
    try {
      resultCreateFunction = await mapClient.createFunction(data);
      onChangeDisabledFunction(true);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "create_function_success" }),
      });
      await props.getFunctionsList(data.uvi!, resultCreateFunction?.data?.id);
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({ id: "create_function_error" })}: ${
          error?.errors ? error.errors[0] : "Unknown error"
        }`,
      });
    }
  }
  async function updateFunction(data: FunctionDTO) {
    try {
      await mapClient.updateFunction(data);
      onChangeDisabledFunction(true);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.info,
        message: intl.formatMessage({ id: "update_function_success" }),
      });
      await props.getFunctionsList(data.uvi!, undefined);
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({ id: "update_function_error" })}: ${
          error?.errors ? error.errors[0] : "Unknown error"
        }`,
      });
    }
  }
  const onSubmit: SubmitHandler<FunctionDTO> = async (data) => {
    if (data.groupId === -1) {
      const group = props.functionGroups.find((f) => f.id === -1);

      if (group && group.groupName) {
        const response = await mapClient.createFunctionGroup(group.groupName);

        if (response.succeeded) {
          data.groupId = response.data?.id ?? data.groupId;
          props.setSelectedRow({
            ...props.selectedRow,
            groupId: data.groupId,
          } as FunctionDTO);
        } else {
          props.onChangeNotificationState({
            isShow: true,
            type: NotificationType.error,
            message: intl.formatMessage({ id: "add_function_error" }),
          });
        }
      } else {
        console.error("Group or groupName is undefined");
      }
    }

    data = changeStatusCriteria(data);
    data = changeData(data);
    if (data.id !== undefined) {
      await updateFunction(data);
    } else {
      await createFunction(data);
    }
  };
  const roles = localStorage.getItem("roles")?.split(",");
  const currentUserRoles = roles?.map((role) => {
    switch (role.toLowerCase()) {
      case "systemadmin":
        return UserRole.SystemAdmin;
      case "admin":
        return UserRole.Admin;
      case "editor":
        return UserRole.Editor;
      case "viewer":
        return UserRole.Viewer;
      default:
        return UserRole.Viewer;
    }
  });
  return (
    <div className="functionList">
      {showModalFunction ? (
        <div>
          <Modal onClickStayPage={onClickButtonBack} onClosePage={onClose} />
        </div>
      ) : null}
      {showModalCan ? (
        <div>
          <CanIdentifier
            canIdentifiers={props.canIdentifiers}
            setShowModalCan={setShowModalCan}
            selectedRow={props.selectedRow}
            setCanIdentifiers={props.setCanIdentifiers}
            showNotification={props.showNotification}
            onChangeNotificationState={props.onChangeNotificationState}
            getFunctionsList={props.getFunctionsList}
          />
        </div>
      ) : null}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className="functionContainer">
          {/*Заголовок с кнопками*/}
          <div className="functionHeader">
            <ButtonBack
              buttonActive={buttonActive}
              onClickButtonBack={onClickButtonBack}
            />
            <h2>
              {props.selectedRow.id
                ? intl.formatMessage({ id: "edit_function_title" })
                : intl.formatMessage({ id: "create_function_title" })}
            </h2>
            {rolesForForms.filter((role) => currentUserRoles?.includes(role))
              .length > 0 ? (
              <>
                <div className="ml-auto">
                  <ButtonEdit
                    onChangeDisabled={onChangeDisabledFunction}
                    isDisabled={props.isDisabledFunction}
                  />
                </div>
                <div className="ml-5">
                  <button type="submit" className="btn ml-auto">
                    <BtnSve />
                    <span className="ml-2.5">
                      <FormattedMessage id="save" />
                    </span>
                  </button>
                </div>
              </>
            ) : null}
          </div>
          <div className="functionBlockContainer">
            <div className="functionsBlock">
              <div className="descriptionBlock mr-5">
                <div className="functionName ">
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="name" />
                    </span>
                  </div>
                  <input
                    {...register("name", {
                      required: intl.formatMessage({ id: "input_required" }),
                    })}
                    className={`${props.isDisabledFunction ? "disabled" : ""}`}
                    type="text"
                    placeholder={intl.formatMessage({ id: "placeholder_name" })}
                    disabled={props.isDisabledFunction}
                  />
                  <div className="mt-1">
                    {errors?.name && (
                      <p>{errors?.name?.message || "Errors!!!"}</p>
                    )}
                  </div>
                </div>
                <div className="functionGroup mb-1">
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="group" />
                    </span>
                  </div>
                  <Controller
                    name="groupId"
                    control={control}
                    rules={{
                      required: intl.formatMessage({ id: "input_required" }),
                    }}
                    render={({ field: { onChange, name, value } }) => {
                      const options = props.functionGroups
                        .filter(
                          (f) => f.id !== undefined && f.groupName !== undefined
                        )
                        .map((f) => ({
                          value: f.id,
                          label: f.groupName,
                        }));

                      const selectedOption = options.find(
                        (option) => option.value === value
                      );

                      return (
                        <Select
                          name={name}
                          placeholder={intl.formatMessage({
                            id: "placeholder_choose_group",
                          })}
                          options={options}
                          isDisabled={props.isDisabledFunction}
                          isSearchable={false}
                          isClearable={false}
                          styles={customStyles}
                          onChange={(selected) => onChange(selected?.value)}
                          value={selectedOption}
                        />
                      );
                    }}
                  />

                  <div className="mt-1">
                    {errors?.groupId && (
                      <p>{errors?.groupId?.message || "Errors!!!"}</p>
                    )}
                  </div>
                </div>
                <div
                  className="changeFunctionGroup mb-2"
                  onClick={() => setShowModalGroups(true)}
                >
                  <div className="group_container">
                    <div
                      className={`group_image ${
                        props.isDisabledFunction ? "disabled" : ""
                      }`}
                    >
                      <EditGroupIcon />
                    </div>
                    <div className="ml-2">
                      <button
                        type="button"
                        className={`group_title ${
                          props.isDisabledFunction ? "disabled" : ""
                        }`}
                      >
                        <FormattedMessage id="changes_group" />
                      </button>
                    </div>
                  </div>
                </div>
                {showModalGroups && !props.isDisabledFunction ? (
                  <div>
                    <FunctionsGroups
                      functionGroups={props.functionGroups}
                      onShowModalGroups={setShowModalGroups}
                      selectedRow={props.selectedRow}
                      setSelectedRow={props.setSelectedRow}
                      setFunctionGroups={props.setFunctionGroups}
                      showNotification={props.showNotification}
                      onChangeNotificationState={
                        props.onChangeNotificationState
                      }
                    />
                  </div>
                ) : null}
                {/*<div className="data_collection_period mb-1">
                  <div className="mb-1">
                    <span className="mb-1">
                      <FormattedMessage id="data_collection_period" />
                    </span>
                  </div>
                  <div>
                    <Select
                      name={"data_collection_period"}
                      placeholder={intl.formatMessage({
                        id: "placeholder_collection_period",
                      })}
                      isDisabled={props.isDisabledFunction}
                      isSearchable={false}
                      isClearable={false}
                      styles={customStyles}
                      options={DataCollectionPeriod}
                    />
                  </div>
                </div>*/}
              </div>
              <div className="descriptionBlock_second mr-5">
                <div className="functionCanID mb-1">
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="can_id" />
                    </span>
                  </div>
                  <Controller
                    name={"canId"}
                    control={control}
                    rules={{
                      required: intl.formatMessage({ id: "input_required" }),
                    }}
                    render={({ field: { onChange, name, value } }) => {
                      const options = props.canIdentifiers.map((c) => ({
                        value: c.canId,
                        label: c.canId,
                      }));

                      const selectedOption = options.find(
                        (option) => option.value === value
                      );

                      return (
                        <Select
                          name={name}
                          onChange={(selected) => onChange(selected?.value)}
                          options={options}
                          value={selectedOption}
                          className={`${props.isDisabledFunction ? "disabled" : ""}`}
                          placeholder={intl.formatMessage({
                            id: "placeholder_canId",
                          })}
                          styles={customStyles}
                          isDisabled={props.isDisabledFunction}
                          isSearchable={false}
                          isClearable={false}
                        />
                      );
                    }}
                  />

                  <div className="mt-1">
                    {errors?.canId && (
                      <p>{errors?.canId?.message || "Errors!!!"}</p>
                    )}
                  </div>
                </div>
                <div className="group_container">
                  <div
                    className={`group_image ${
                      props.isDisabledFunction ? "disabled" : "active"
                    }`}
                  >
                    <EditGroupIcon />
                  </div>
                  <div className="ml-2">
                    <button
                      type="button"
                      disabled={props.isDisabledFunction}
                      className={`group_title ${
                        props.isDisabledFunction ? "disabled" : "active"
                      }`}
                      onClick={() => setShowModalCan(true)}
                    >
                      <FormattedMessage id="changes_CanId" />
                    </button>
                  </div>
                </div>
                <div className="functionDataType">
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="data_type" />
                    </span>
                  </div>
                  <div>
                    <Controller
                      name={"dataType"}
                      control={control}
                      rules={{
                        required: intl.formatMessage({ id: "input_required" }),
                      }}
                      render={({ field: { value, onChange, name } }) => {
                        const selectedOption = optionsDataType.find(
                          (option) => option.value === value
                        );

                        return (
                          <Select
                            name={name}
                            placeholder={intl.formatMessage({
                              id: "placeholder_choose_data_type",
                            })}
                            isSearchable={false}
                            isClearable={false}
                            options={optionsDataType}
                            styles={customStyles}
                            isDisabled={props.isDisabledFunction}
                            onChange={(selected) => {
                              if (selected?.value) {
                                setDataType(selected.value);
                              }
                              setValue("dataBit", undefined);
                              setValue("dataSigned", undefined);
                              setValue("dataOrder", undefined);
                            }}
                            value={selectedOption}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className="mt-1">
                    {errors?.dataType && (
                      <p>{errors?.dataType?.message || "Errors!!!"}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="descriptionBlock_third">
                <div className="functionDataByte mb-1">
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="data_byte" />
                    </span>
                  </div>
                  <Controller
                    name={"dataByte"}
                    control={control}
                    rules={{
                      required: intl.formatMessage({ id: "input_required" }),
                    }}
                    render={({ field: { value, onChange, name } }) => {
                      const selectedOption = optionsDataByte.find(
                        (option) => option.value === value
                      );

                      return (
                        <Select
                          name={name}
                          placeholder={intl.formatMessage({
                            id: "placeholder_choose_data_byte",
                          })}
                          isSearchable={false}
                          isClearable={false}
                          options={optionsDataByte}
                          styles={customStyles}
                          isDisabled={props.isDisabledFunction}
                          onChange={(selected) => onChange(selected?.value)}
                          value={selectedOption}
                        />
                      );
                    }}
                  />
                  <div className="mt-1">
                    {errors?.dataByte && (
                      <p>{errors?.dataByte?.message || "Errors!!!"}</p>
                    )}
                  </div>
                </div>
                <div className="functionDataBit mb-1">
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="data_bit" />
                    </span>
                  </div>
                  <Controller
                    name={"dataBit"}
                    control={control}
                    rules={{
                      required:
                        dataType === DataType.Bit
                          ? intl.formatMessage({ id: "input_required" })
                          : false,
                    }}
                    render={({ field: { value, name, onChange } }) => (
                      <Select
                        name={name}
                        isSearchable={false}
                        isClearable={false}
                        options={optionsDataBit}
                        placeholder={intl.formatMessage({
                          id: "placeholder_choose_data_bit",
                        })}
                        styles={customStyles}
                        isDisabled={
                          props.isDisabledFunction || dataType !== DataType.Bit
                        }
                        onChange={(val) => {
                          onChange(val?.value ?? undefined);
                        }}
                        value={optionsDataBit.find(
                          (option) => option.value === value ?? undefined
                        )}
                      />
                    )}
                  />
                  <div className="mt-1">
                    {errors?.dataBit && (
                      <p>{errors?.dataBit?.message || "Errors!!!"}</p>
                    )}
                  </div>
                </div>
                <div className="functionSigned mb-1">
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="signed_unsigned" />
                    </span>
                  </div>
                  <Controller
                    name={"dataSigned"}
                    control={control}
                    rules={{
                      required:
                        dataType === DataType.TwoByte
                          ? intl.formatMessage({ id: "input_required" })
                          : false,
                    }}
                    render={({ field: { value, onChange, name } }) => (
                      <Select
                        name={name}
                        isSearchable={false}
                        isClearable={false}
                        placeholder={intl.formatMessage({
                          id: "placeholder_signed_unsigned",
                        })}
                        options={optionsDataSigned}
                        styles={customStyles}
                        isDisabled={
                          props.isDisabledFunction ||
                          dataType !== DataType.TwoByte
                        }
                        onChange={(value) => {
                          onChange(value?.value ?? undefined);
                        }}
                        value={optionsDataSigned.find(
                          (option) => option.value === value ?? undefined
                        )}
                      />
                    )}
                  />
                  <div className="mt-1">
                    {errors?.dataSigned && (
                      <p>{errors?.dataSigned?.message || "Errors!!!"}</p>
                    )}
                  </div>
                </div>
                <div className="functionDataOrder mb-1">
                  <div className="mb-1">
                    <span>
                      <FormattedMessage id="order" />
                    </span>
                  </div>
                  <Controller
                    name={"dataOrder"}
                    control={control}
                    rules={{
                      required:
                        dataType === DataType.TwoByte
                          ? intl.formatMessage({ id: "input_required" })
                          : false,
                    }}
                    render={({ field: { value, onChange, name } }) => (
                      <Select
                        name={name}
                        isSearchable={false}
                        isClearable={false}
                        placeholder={intl.formatMessage({
                          id: "placeholder_order",
                        })}
                        options={optionsDataOrder}
                        styles={customStyles}
                        isDisabled={
                          props.isDisabledFunction ||
                          dataType !== DataType.TwoByte
                        }
                        onChange={(value) => {
                          onChange(value?.value ?? undefined);
                        }}
                        value={optionsDataOrder.find(
                          (option) => option.value === value ?? undefined
                        )}
                      />
                    )}
                  />
                  <div className="mt-1">
                    {errors?.dataOrder && (
                      <p>{errors?.dataOrder?.message || "Errors!!!"}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="descriptionBlock_notes">
              <div className="functionNotes">
                <div className="mb-1">
                  <span>
                    <FormattedMessage id="notes" />
                  </span>
                </div>
                <textarea
                  {...register("note")}
                  className={`${props.isDisabledFunction ? "disabled" : ""}`}
                  placeholder={intl.formatMessage({
                    id: "placeholder_notes",
                  })}
                  disabled={props.isDisabledFunction}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="statusBlock mt-2.5">
            {dataType !== undefined ? (
              <StatusSettings
                formMethods={methods}
                statusFunctions={props.statusFunctions}
                criteriaParameters={props.criteriaParameters}
                selectedRow={props.selectedRow}
                setSelectedRow={props.setSelectedRow}
                isDisabledFunction={props.isDisabledFunction}
                dataType={dataType}
              />
            ) : null}
          </div>
        </form>
      </FormProvider>
    </div>
  );
}

export default Function;
